// Table
window.Table = function () {
};

Table.list = [];

Table.init = function () {
    'use strict';

    var tables = $('table.dataTable');

    $.each(tables, function (index, el) {
        el = $(el);

        var table = el.DataTable({
            processing: true,
            ajax: el.data('module') + '/data',
            columns: Table.getColumns(el),
            dom: "<'row'<'col-sm-8'f><'col-sm-4'i>>" +
            "<'row'<'col-sm-12'tr>>",
            stripeClasses: [''],
            select: {
                style: 'multi',
                selector: 'td.select-checkbox'
            },
            deferRender: true,
            scrollY: window.innerHeight,
            scrollCollapse: true,
            scroller: true,
            order: Table.getOrders(el),
            initComplete: function (settings, json) {
                Table.list.push(this);

                Table.setButtons(this);
                Table.setAction(this);
                Table.setFilters(this);
                Table.setSortable(this);
                Table.setHeight(this);
            },
            drawCallback: function (settings) {
                Table.rowGrouping(this);
            }
        });

        table.on('draw', function () {
            var body = $(table.table().body());

            body.unhighlight();
            body.highlight(table.search());
        });
    });
};

Table.getColumns = function (table) {
    'use strict';

    var header = $('thead tr th', table);
    var orderable = table.data('orderable') != false;
    var permission = table.data('action').split('|');

    return header.map(function (index, column) {
        column = $(column);
        var data = {};

        var select = column.attr('select') != undefined;
        var sortable = column.attr('sortable') != sortable;
        var action = column.attr('action') != undefined;

        if (select || sortable || action || !orderable)
            data.orderable = false;

        if (select || sortable || action) {
            data.data = 'id';

            if (select) {
                if (
                    $.inArray('modify', permission) > -1 ||
                    $.inArray('delete', permission) > -1
                ) {
                    data.width = '31px';
                    data.className = 'select-checkbox';
                    data.render = function (data, type, full, meta) {
                        return '';
                    };
                } else
                    data.visible = false;
            }

            if (sortable) {
                if ($.inArray('modify', permission) > -1) {
                    data.width = '14px';
                    data.className = 'sortable';
                    data.render = function (data, type, full, meta) {
                        return Template.render(Template.sort_handle, {
                            data: data,
                            sequence: full.sequence
                        });
                    };
                } else
                    data.visible = false;
            }

            if (action) {
                if ($.inArray('view', permission) > -1) {
                    data.width = '92px';
                    data.render = function (data, type, full, meta) {
                        return Template.render(Template.action_button, {
                            id: data,
                            delete: $.inArray('delete', permission) > -1 ? Template.action_delete : ''
                        });
                    };
                } else
                    data.visible = false;
            }
        } else {
            var col_data = column.data('data');
            var type = column.data('type');

            data.data = type == 'combine' ? 'id' : col_data;
            data.type = Table.columnType(type);
            data.render = Table.columnRender(type, false, col_data);
            data.visible = col_data != table.data('grouping') && column.data('visible') != false;
        }

        return data;
    }).get();
};

Table.getOrders = function (table) {
    'use strict';

    var header = $('thead tr th', table);

    var orders = header.map(function (index, column) {
        if ($(column).data('order') != undefined)
            return [[index, $(column).data('order')]];
        else
            return false;
    }).get();

    orders = orders.filter(function (column) {
        return column != false;
    });

    if (orders.length == 0)
        orders = [[1, 'asc']];

    return orders;
};

Table.columnType = function (type) {
    'use strict';

    switch (type) {
        case 'numeric':
        case 'date':
        case 'time':
        case 'datetime':
            return 'html-num-fmt';

        default:
            return 'html';
    }
};

Table.columnRender = function (type, textOnly, col_data) {
    'use strict';

    switch (type) {
        case 'numeric':
            return function (data, type, full, meta) {
                return numeral(data).format('0,0');
            };

        case 'boolean':
            return function (data, type, full, meta) {
                if (data == 1)
                    return Template.enable_label;
                else
                    return Template.disable_label;
            };

        case 'date':
            return function (data, type, full, meta) {
                return moment(data).format('YYYY-MM-DD');
            };

        case 'time':
            return function (data, type, full, meta) {
                return moment(data).format('HH:mm');
            };

        case 'datetime':
            return function (data, type, full, meta) {
                return moment(data).format('YYYY-MM-DD HH:mm');
            };

        case 'icon':
            return function (data, type, full, meta) {
                return Template.render(Template.icon, {
                    icon: data
                });
            };

        case 'combine':
            return function (data, type, full, meta) {
                col_data = col_data.split(',');

                return col_data.map(function (el, index) {
                    return eval('full.' + el);
                }).join(' ');
            };

        case 'label':
            return function (data, type, full, meta) {
                var temp = $('<div>');

                if (data != null)
                    $.each(data, function (index, el) {
                        $(Template.render(Template.label, {
                            label: el
                        })).appendTo(temp);
                    });

                return temp.html().replace('span><span', 'span> <span');
            };
        default:
            return function (data, type, full, meta) {
                return data;
            };
    }
};

Table.setButtons = function (table) {
    'use strict';

    var el = $(table);
    var filter = el.closest('.dataTables_wrapper').find('.dataTables_filter');
    var action = el.data('action').split('|');

    filter.addClass('btn-toolbar').attr('role', 'toolbar').attr('aria-label', '...');

    var columns = Table.getColumns(el).map(function (column, index) {
        return column.data;
    });

    var action_bar = $(Template.render(Template.action_bar, {
        add: $.inArray('create', action) > -1 ? Template.action_bar_add : '',
        delete: $.inArray('delete', action) > -1 ? Template.action_bar_delete : '',
        status: $.inArray('enable', columns) > -1 && $.inArray('modify', action) > -1 ? Template.action_bar_status : ''
    }));

    action_bar.prepend($('.addon-btn'));
    action_bar.appendTo(filter);

    $(Template.action_bar_filter).append(
        $('input', filter).attr('placeholder', 'Filter')
    ).appendTo(filter);

    $('label', filter).remove();
};

Table.setFilters = function (table) {
    'use strict';

    var scroll_table = $(table).closest('.dataTables_scroll');
    $('.dataTables_scrollFoot', scroll_table).insertAfter($('.dataTables_scrollHead', scroll_table));

    var columns = table.api().columns();

    columns.every(function (index) {
        var column = this;
        var footer = $(column.footer());
        var filter = footer.data('filter');

        footer.empty();

        switch (filter) {
            case 'select':
                var select = $(Template.select);

                var render = Table.columnRender($(column.header()).data('type'), true);

                column.data().unique().sort().each(function (data, index, api) {
                    var value = $('<div>').append(render(data)).text();

                    select.append(
                        $(
                            Template.render(Template.option, {
                                value: value,
                                text: value
                            })
                        )
                    );
                });

                footer.append(select);
                select.on('change', function (event) {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                });

                select.select2({
                    theme: 'bootstrap'
                });
                break;

            case 'input':
                var input = $(
                    Template.render(Template.input, {
                        maxlength: 200
                    })
                );

                footer.append(input);

                input.on('keyup', function (event) {
                    column.search($(this).val()).draw();
                });

                input.maxlength();
                break;
        }
    });
};

Table.setHeight = function (table) {
    'use strict';

    var body = $(table).closest('.dataTables_scrollBody');
    body.height(window.innerHeight);
    body.height(2 * window.innerHeight - document.body.scrollHeight - 10);
};

Table.setAction = function (table) {
    'use strict';

    var el = $(table);
    var module = el.data('module');
    var table_wrapper = el.closest('.dataTables_wrapper');

    $(table_wrapper).on('click', '[action=add]', function (event) {
        Modal.showCreate(module);
    });

    $(table_wrapper).on('click', '[action=deleteSelected]', function (event) {
        var selected = table.api().rows({
            selected: true
        });

        var count = selected.count();

        if (count == 0)
            Message.error('No records were selected');
        else {
            var message = 'Are you sure you want to delete ' + (count == 1 ? 'this record' : ('these ' + count + ' records')) + '?';
            Message.confirmation(message, function (el) {
                var url = module + '/delete';

                $.get('/token', function (token) {
                    var ids = $.map(selected.data(), function (data, index) {
                        return data.id;
                    });

                    Form.ajaxSubmit(url, 'DELETE', Form.getData({
                        _token: token,
                        ids: ids
                    }));
                });
            });
        }
    });

    $(table_wrapper).on('click', '[action=status]', function (event) {
        var el = $(this);
        var selected = table.api().rows({
            selected: true
        });

        var count = selected.count();

        if (count == 0)
            Message.error('No records were selected');
        else {
            var url = module + '/status';
            var status = el.attr('status');

            $.get('/token', function (token) {
                var ids = $.map(selected.data(), function (data, index) {
                    return data.id;
                });

                Form.ajaxSubmit(url, 'POST', Form.getData({
                    _token: token,
                    ids: ids,
                    status: status
                }));
            });
        }
    });

    $(table_wrapper).on('click', '[action=refresh]', function (event) {
        Table.reload(table);
    });

    $(table_wrapper).on('click', '[action=view]', function (event) {
        var el = $(this);
        var id = el.closest('.btn-action').data('id');
        Modal.showView(module, id);
    });

    $(table_wrapper).on('click', '[action=delete]', function (event) {
        var el = $(this);
        var id = el.closest('.btn-action').data('id');

        Message.confirmation('Are you sure you want to delete this record?', function (el) {
            var url = module + '/delete';

            $.get('/token', function (token) {

                Form.ajaxSubmit(url, 'DELETE', Form.getData({
                    _token: token,
                    ids: [id]
                }));
            });
        });
    });
};

Table.rowGrouping = function (table) {
    'use strict';

    var el = $(table).closest('table');
    var grouping = el.data('grouping');

    if (grouping != undefined) {
        var count = $('thead tr > *', el).length;
        var last = null;

        table.api().rows({
            filter: 'applied'
        }).every(function (index, tableLoop, rowLoop) {
            var group = eval('this.data().' + grouping);

            if (last != group)
                $(
                    Template.render(Template.row_grouping, {
                        colspan: count,
                        group: group
                    })
                ).insertBefore(this.node());

            $(this.node()).attr('group', group);

            last = group;
        });
    }
};

Table.setSortable = function (table) {
    'use strict';

    var el = $(table);
    var tbody = el.closest('table').find('tbody');
    var module = el.data('module');
    var url = el.data('url');
    var enable = true;

    $(tbody).on('mousedown', '.sortable i', function (mdEvent) {
        mdEvent.preventDefault();
        mdEvent.stopPropagation();

        if (enable) {
            var el = $(this).closest('tr');
            var group = el.attr('group');
            var selector = 'tr[role=row]';
            var label = $(Template.sort_label);

            el.addClass('draging');
            label.appendTo(body);

            if (group != undefined)
                selector += '[group="' + group + '"]';

            if (window.updateLabelPosition == null)
                window.updateLabelPosition = function (label, x, y) {
                    x = x - 18;
                    y = y - 15;

                    label.css({
                        top: y + 'px',
                        left: x + 'px'
                    });
                };

            updateLabelPosition(label, mdEvent.clientX, mdEvent.clientY);

            var sequence = $(selector).find('.sortable i').map(function (index, arrow) {
                return $(arrow).data('sequence');
            }).get();

            $(document).on('mousemove', function (mmEvent) {
                mmEvent.preventDefault();

                if (mmEvent.buttons > 0) {
                    updateLabelPosition(label, mmEvent.clientX, mmEvent.clientY);

                    var swap = $.touching({
                        x: mmEvent.clientX,
                        y: mmEvent.clientY
                    }, selector, {
                        container: tbody,
                        sort: 'nearest'
                    }).eq(0);

                    if (!el.is(swap))
                        if (el.index() < swap.index())
                            el.insertAfter(swap);
                        else
                            el.insertBefore(swap);
                }
                else {
                    $(document).off('mousemove');
                    el.removeClass('draging');
                    label.remove();

                    if (module != undefined)
                        url = module + '/sort';

                    $.get('/token', function (token) {
                        var data = $(selector).find('.sortable i').map(function (index, arrow) {
                            return {
                                id: $(arrow).data('id'),
                                sequence: sequence[index]
                            };
                        }).get();

                        enable = false;

                        Form.ajaxSubmit(url, 'POST', Form.getData({
                            _token: token,
                            data: data
                        }), function () {
                            enable = true;
                        });
                    });
                }
            });
        }
    });
};

Table.reload = function (table) {
    'use strict';

    Common.refreshNavBar();

    if (table != undefined)
        table.api().ajax.reload(function (json) {
            Table.setFilters(table);
        });
    else
        $.each(Table.list, function (index, table) {
            table.api().ajax.reload(function (json) {
                Table.setFilters(table);
            });
        });
};