// Message
window.modal = $('#modal');

window.Modal = function () {
};

Modal.ajaxAction = {
    create: 'POST',
    modify: 'PUT',
    delete: 'DELETE'
};

Modal.init = function () {
    'use strict';

    modal.modal({
        backdrop: 'static',
        show: false
    });

    $(body).on('click', '[data-modal]', function (event) {
        event.preventDefault();

        var el = $(this);
        var url = el.data('modal');

        Modal.show(url);
    });

    $(modal).on('click', '[data-action=modify]', function (event) {
        $('.modal-content', modal).attr('action', 'modify');
        Modal.updateModal();

        tinymce.remove('[tinymce]');

        $('[readonly]', modal).each(function (index, el) {
            el = $(el);

            el.removeAttr('readonly');

            if (el.attr('disabled') != undefined) {
                el.removeAttr('disabled');

                if (el.is('.icon-picker'))
                    el.attr('readonly', true);
            }
        });

        tinymce.init($.extend({
            selector: '[tinymce]:not([readonly])'
        }, tinymceDefault));

        tinymce.init($.extend({
            selector: '[tinymce][readonly]',
            readonly: 1
        }, tinymceDefault));
    });

    $(modal).on('click', '[data-action=save]', function (event) {
        var content = $('.modal-content', modal);
        var action = content.attr('action');
        var module = content.data('module');
        var url = '/' + module + (action == 'modify' ? ('/update/' + content.data('id')) : '/store');

        tinymce.triggerSave();
        Form.ajaxSubmit(url, Modal.ajaxAction[action], new FormData($('form', modal).get(0)));
    });

    $(modal).on('click', '[data-action=delete]', function (event) {
        var content = $('.modal-content', modal);
        var module = content.data('module');
        var url = '/' + module + '/delete/' + content.data('id');

        Message.confirmation('Are you sure you want to delete this record?', function (el) {
            Form.ajaxSubmit(url, 'DELETE', new FormData($('form', modal).get(0)));
        });
    });

    $(modal).on('shown.bs.modal', function (e) {
        location.hash = '#' + modal.data('url');
    });

    $(modal).on('hidden.bs.modal', function (e) {
        tinymce.remove('[tinymce]');
        modal.find('.modal-dialog').empty();
        modal.data('check', '');
        modal.data('url', '');
        location.hash = '#';
    });

    $(body).on('dp.show', function (event) {
        var picker = $('.bootstrap-datetimepicker-widget');
        var offset = $(event.target).offset();

        picker.css({
            top: offset.top + 10,
            left: offset.left + 10 - modal.children('.modal-dialog').offset().left
        })
    });

    $(body).on('iconpickerShown', function (event) {
        $('.iconpicker-items:not(.mCustomScrollbar)').mCustomScrollbar({
            theme: 'minimal-dark'
        });
    });

    $(modal).on('click', '[new-row]', function (event) {
        event.preventDefault();

        var el = $(this);
        var table = $(el.attr('new-row'));
        var row = $('.record-template', table);
        row.clone().removeClass('record-template').addClass('record').appendTo(row.parent());

        updateRecord(table);
    });

    $(modal).on('click', '.record .delete button', function (event) {
        event.preventDefault();

        var row = $(this).closest('tr');
        var table = row.closest('table');

        row.remove();
        updateRecord(table);
    });

    $(window).on('hashchange', Modal.showHashModal);

    function updateRecord(table) {
        var records = $('tr.record', table);

        $.each(records, function (index, record) {
            record = $(record);
            var id = records.index(record);

            $('input', record).each(function (index, el) {
                el = $(el);
                el.attr('name', el.data('name').replace('{id}', id));
            });
        });
    }
};

Modal.show = function (url) {
    'use strict';

    if (url != undefined)
        if (Modal.loadContent(url))
            modal.modal('show');
};

Modal.showCreate = function (url) {
    'use strict';

    Modal.show(url + '/create');
};

Modal.showView = function (url, id) {
    'use strict';

    Modal.show(url + '/show/' + id);
};

Modal.loadContent = function (url) {
    'use strict';

    var success = false;
    url = Common.parseURL(url);

    $.ajax({
        url: url.pathname,
        async: false
    }).done(function (response) {
        var check = md5(response);

        if (modal.data('check') != check)
            modal.children('.modal-dialog').html(response);

        modal.data('check', check);
        modal.data('url', url.pathname.replace(location.pathname, ''));

        $('.tab-pane', modal).mCustomScrollbar({
            theme: 'minimal-dark'
        });
        Form.initInput(modal.find('form'));
        Modal.updateModal();

        success = true;
    }).fail(function (response) {
        Message.error('Cannot load page content');
    });

    return success;
};

Modal.updateModal = function () {
    'use strict';

    var action = $('.modal-content', modal).attr('action');

    $('.for-create, .for-view-only, .for-view, .for-modify', modal).hide();
    $('.for-' + action, modal).show();
};

Modal.showHashModal = function () {
    'use strict';

    if (location.hash != '' && location.hash != '#')
        if (!modal.is(':visible'))
            if (Modal.loadContent(location.hash.replace('#', location.pathname)))
                modal.modal('show');
};