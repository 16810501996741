// Setting
$(document).ready(function () {
    'use strict';

    Table.init();
    Form.init();
    Modal.init();
    Common.setPanelHeight();

    Modal.showHashModal();
    Common.authCheck();
});