// Message
$.extend($.noty.defaults, {
    layout: 'topRight',
    theme: 'bootstrapTheme',
    timeout: 5000,
    maxVisible: 15
});

window.Message = function () {
};

Message.show = function (messages) {
    'use strict';

    $.each(messages, function (key, message) {
        if (!$.isNumeric(key))
            $('#' + key.replace(/\./g, '-')).parent().addClass('has-error');

        noty({
            type: message.type,
            text: message.text
        });
    });
};

Message.showOne = function (type, message) {
    'use strict';

    Message.show([
        {
            type: type,
            text: message
        }
    ]);
};

Message.alert = function (message) {
    'use strict';

    Message.showOne('alert', message);
};

Message.success = function (message) {
    'use strict';

    Message.showOne('success', message);
};

Message.error = function (message) {
    'use strict';

    Message.showOne('error', message);
};

Message.warning = function (message) {
    'use strict';

    Message.showOne('warning', message);
};

Message.information = function (message) {
    'use strict';

    Message.showOne('information', message);
};

Message.confirmation = function (message, confirmCallback, cancelCallback) {
    'use strict';

    noty({
        layout: 'center',
        type: 'confirmation',
        text: message,
        buttons: [
            {
                addClass: 'btn btn-default btn-sm',
                text: 'Cancel',
                onClick: function (el) {
                    el.close();
                    if (cancelCallback != undefined)
                        cancelCallback(el);
                }
            },
            {
                addClass: 'btn btn-success btn-sm',
                text: 'Confirm',
                onClick: function (el) {
                    el.close();
                    if (confirmCallback != undefined)
                        confirmCallback(el);
                }
            }
        ]
    });
};

Message.clear = function () {
    'use strict';

    $('.has-error').removeClass('has-error');
    $.noty.closeAll();
};