//Common

window.Common = function () {
};

// Refresh Navigation Bar
Common.refreshNavBar = function () {
    'use strict';

    $.get('/navbar', function (navbar) {
        $('nav', body).remove();

        $(body).prepend(navbar);
    });
};

// Set Panel Height
Common.setPanelHeight = function () {
    'use strict';

    var panel = $('.panel-fit');
    panel.height(window.innerHeight);
    panel.height(2 * window.innerHeight - document.body.scrollHeight - 10);

    panel.mCustomScrollbar({
        theme: 'minimal-dark'
    });
};

// Parse URL
Common.parseURL = function (url) {
    'use strict';

    return $('<a>', {
        href: url
    }).get(0);
};

Common.authCheck = function () {
    'use strict';

    setInterval(function () {
        $.ajax({
            url: '/auth/check',
            type: 'GET',
            dataType: 'json'
        })
            .done(function (response) {
                if (!response.data.authenticed)
                    location.reload();
            });
    }, 60000);
};