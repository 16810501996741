// Form
$.fn.select2.defaults.set('width', '100%');

$.fn.o_select2 = $.fn.select2;

$.fn.select2 = function (options) {
    'use strict';

    var el = this.o_select2(options);

    if (el.hasClass('input-xs'))
        el.next('.select2-container').addClass('input-xs');

    if (el.hasClass('input-sm'))
        el.next('.select2-container').addClass('input-sm');

    if (el.hasClass('input-md'))
        el.next('.select2-container').addClass('input-md');

    if (el.hasClass('input-lg'))
        el.next('.select2-container').addClass('input-lg');

    return el;
};

$.fn.filemanager = function (type) {
    if (type == null || type == undefined || type === 'image' || type === 'images')
        type = 'Images';
    else
        type = 'Files';

    var input_id = this.data('input');
    var preview_id = this.data('preview');

    this.on('click', function (event) {
        localStorage.setItem('target_input', input_id);
        localStorage.setItem('target_preview', preview_id);
        window.open('/laravel-filemanager?type=' + type, 'FileManager', 'width=1000,height=750');
        return false;
    });
};

function SetUrl(url) {
    var target_input = $('#' + localStorage.getItem('target_input'));
    target_input.val(url);

    var target_preview = $('#' + localStorage.getItem('target_preview'));
    target_preview.attr('src', url);
}


window.Form = function () {
};

Form.init = function () {
    'use strict';

    $(body).on('submit', 'form[ajax]', function (event) {
        event.preventDefault();

        var el = $(this);
        var url = el.attr('action');
        var type = el.attr('method');

        url = url == '' ? window.location.pathname : url;

        Form.ajaxSubmit(url, type, new FormData(el.get(0)));
    });

    $(body).on('focus', '.has-error *', function (event) {
        $(this).closest('.form-group').removeClass('has-error');
    });
};

Form.getData = function (data) {
    'use strict';

    var form = new FormData();

    for (var key in data)
        if (typeof data == 'object')
            form.append(key, JSON.stringify(data[key]));
        else
            form.append(key, data[key]);

    return form;
};

Form.ajaxSubmit = function (url, type, data, always) {
    'use strict';

    data.append('_method', type);

    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
        cache: false,
        processData: false,
        contentType: false
    })
        .always(function () {
            if (typeof always == 'function')
                always();

            Message.clear()
        })
        .done(Form.handleResponse);
};

Form.initInput = function (form) {
    'use strict';

    $('select:not([tag]):not(.template)', form)
        .select2({
            placeholder: 'Please Select',
            theme: 'bootstrap',
            allowClear: true
        })
        .on('select2:unselecting', function (event) {
            $(this).data('state', 'unselected');
        })
        .on('select2:open', function (event) {
            if ($(this).data('state') === 'unselected') {
                $(this).removeData('state');

                var self = $(this);
                setTimeout(function () {
                    self.select2('close');
                }, 5);
            }
        });

    $('select[tag]:not(.template)', form)
        .select2({
            placeholder: 'Please Select',
            theme: 'bootstrap',
            allowClear: true,
            tags: true
        })
        .on('select2:unselecting', function (event) {
            $(this).data('state', 'unselected');
        })
        .on('select2:open', function (event) {
            if ($(this).data('state') === 'unselected') {
                $(this).removeData('state');

                var self = $(this);
                setTimeout(function () {
                    self.select2('close');
                }, 5);
            }
        });

    $('[maxlength]', form).maxlength();

    $('.icon-picker', form).iconpicker({
        timeZone: '',
        placement: 'bottomLeft',
        hideOnSelect: true,
        component: '.icon-picker + .input-group-addon',
        container: modal.children('.modal-dialog')
    });

    $('[data-picker=date]', form).datetimepicker({
        timeZone: '',
        format: 'YYYY-MM-DD',
        showTodayButton: true,
        showClear: true,
        widgetParent: modal.children('.modal-dialog')
    });

    $('[data-picker=time]', form).datetimepicker({
        timeZone: '',
        format: 'HH:mm',
        showTodayButton: true,
        showClear: true,
        widgetParent: modal.children('.modal-dialog')
    });

    $('[data-picker=datetime]', form).datetimepicker({
        format: 'YYYY-MM-DD HH:mm',
        showTodayButton: true,
        showClear: true,
        sideBySide: true,
        widgetParent: modal.children('.modal-dialog')
    });

    $('.filemanager', form).each(function (index, filemanager) {
        filemanager = $(filemanager);

        filemanager.filemanager(filemanager.data('type'));
    });

    $('.dropzone', form).each(function (index, dropzone) {
        dropzone = $(dropzone);

        dropzone.dropzone({
            url: dropzone.data('url'),
            acceptedFiles: dropzone.data('acceptedFiles'),
            dictDefaultMessage: '<i class="fa fa-cloud-upload fa-fw"></i> Drop files here or click to upload.',
            complete: function (file) {
                if (file.status) {
                    var callback = window[dropzone.data('callback')];

                    this.removeFile(file);
                    if (typeof callback == 'function')
                        callback(file);
                }
            },
            sending: function (file, xhr, formData) {
                formData.append('_token', $('[name=_token]', form).val());
            }
        });
    });

    $('table', form).each(function (index, table) {
        Table.setSortable(table);
    });

    tinymce.init($.extend({
        selector: '[tinymce]:not([readonly])'
    }, tinymceDefault));

    tinymce.init($.extend({
        selector: '[tinymce][readonly]',
        readonly: 1
    }, tinymceDefault));
};

Form.handleResponse = function (response) {
    'use strict';

    switch (response.action) {
        case 'message':
            Message.show(response.message);
            if (response.close == 'auto' && response.message.length == 1 && response.message[0].type == 'success')
                if (response.id == '')
                    modal.modal('hide');
            else if (response.close == true)
                modal.modal('hide');

            Table.reload();
            break;

        case 'redirect':
            window.location.href = response.path;
            break;

        case 'reload':
            window.location.reload(true);
    }
};