// Template

window.Template = {
    sort_handle: '' +
    '<i class="fa fa-arrows fa-fw" data-id="{{ data }}" data-sequence="{{ sequence }}"></i>',

    sort_label: '' +
    '<button class="btn btn-sm drag-drop-label">' +
    '   <i class="fa fa-arrows fa-fw"></i> Drop to <b>Reorder</b> list' +
    '</button>',

    icon: '' +
    '<i class="fa {{ icon }} fa-fw"></i>',

    label: '' +
    '<span class="label label-primary">{{ label }}</span>',

    enable_label: '' +
    '<span class="label label-success">✔</span>',

    disable_label: '' +
    '<span class="label label-danger">✘</span>',

    action_button: '' +
    '<div class="btn-group btn-action" data-id="{{ id }}">' +
    '   <button type="button" class="btn btn-default btn-sm" action="view">' +
    '       <i class="fa fa-eye fa-fw"></i> View' +
    '   </button>' +
    '   {{ delete }}' +
    '</div>',

    action_delete: '' +
    '<button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
    '   <span class="caret"></span>' +
    '   <span class="sr-only">Toggle Dropdown</span>' +
    '</button>' +
    '<ul class="dropdown-menu dropdown-menu-right">' +
    '   <li>' +
    '       <a href="#" action="delete">' +
    '           <i class="fa fa-trash-o fa-fw"></i>Delete' +
    '       </a>' +
    '   </li>' +
    '</ul>',

    action_bar: '' +
    '<div class="btn-group btn-group-sm" role="group" aria-label="...">' +
    '   {{ add }}' +
    '   {{ delete }}' +
    '   {{ status }}' +
    '   <button type="button" class="btn btn-default" data-toggle="tooltip" data-placement="top" action="refresh" title="" data-original-title="Refresh">' +
    '       <i class="fa fa-refresh fa-fw"></i>' +
    '   </button>' +
    '</div>',

    action_bar_add: '' +
    '<button type="button" class="btn btn-primary" action="add">' +
    '   <i class="fa fa-plus fa-fw"></i> Add New' +
    '</button>',

    action_bar_delete: '' +
    '<button type="button" class="btn btn-danger" data-toggle="tooltip" data-placement="top" action="deleteSelected" title="" data-original-title="Delete">' +
    '   <i class="fa fa-trash-o fa-fw"></i>' +
    '</button>',

    action_bar_status: '' +
    '<div class="btn-group btn-group-sm" role="group">' +
    '   <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
    '       <i class="fa fa-square-o fa-fw"></i>' +
    '       <span class="caret"></span>' +
    '   </button>' +
    '   <ul class="dropdown-menu">' +
    '       <li>' +
    '           <a href="#" action="status" status="enable">' +
    '               <span class="label label-success">✔</span> Enable' +
    '           </a>' +
    '           <a href="#" action="status" status="disable">' +
    '               <span class="label label-danger">✘</span> Disable' +
    '           </a>' +
    '       </li>' +
    '   </ul>' +
    '</div>',

    action_bar_filter: '' +
    '<div class="input-group">' +
    '   <span class="input-group-addon">' +
    '       <i class="fa fa-search fa-fw"></i>' +
    '   </span>' +
    '</div>',

    input: '' +
    '<input type="text" class="form-control input-sm" maxlength="{{ maxlength }}">',

    select: '' +
    '<select class="form-control input-sm">' +
    '   <option value="">Please select</option>' +
    '</select>',

    option: '' +
    '<option value="{{ value }}">{{ text }}</option>',

    row_grouping: '' +
    '<tr class="row-grouping" role="row">' +
    '   <td colspan="{{ colspan }}">' +
    '       <i class="fa fa-list fa-fw"></i> {{ group }}' +
    '   </td>' +
    '</tr>',
};

Template.render = function (template, binding) {
    'use strict';

    $.each(binding, function (key, value) {
        template = template.replace('{{ ' + key + ' }}', value);
    });

    return template.replace(/  +/g, '\n');
};